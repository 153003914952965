export const DEFAULT_WAREHOUSE_ID = '2729dba5-0af4-445c-a82a-86ce24e77246'

export const lowerTempCelsius = -1
export const upperTempCelsius = 8

export const lowerTempFahrenheit = 39
export const upperTempFahrenheit = 43

export const formatDateCH = 'dd.MM.yyyy'
export const formatDateTimeCH = 'dd.MM.yyyy HH:mm'
export const formatDateUS = 'MM/dd/yyyy'
export const formatDateTimeUS = 'MM/dd/yyyy HH:mm'

export const noQcErrorMessage = 'The product must first pass quality control.'